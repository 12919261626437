import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import { format } from 'date-fns'
import Layout from "../components/layout"
import htmlDecode from "html-entities-decoder";

import { el } from 'date-fns/locale'

import User from "./../images/user.svg"
import Calendar from "./../images/calendar.svg"

import Breadcrumb from "../components/Global/Breadcrumb"

import "./../css/BlogTemplate.css"



function BlogPageTemplate(props) {
    let data = props.data.wpgraphql;

    function constructMetaData(page, currentPage) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: page.seo.canonical,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: page.seo.canonical + props.pageContext.currentPage == 1 ? `/` : `/blog/${props.pageContext.currentPage}`,
            fbType: "website",
            locale: 'el',
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    function getBreadcrumb() {
        let bCrumb = [];
        bCrumb.push({
            text: "Home",
            url: `${props.pageContext.language !== "EL" ? "/" + props.pageContext.language.toLowerCase() : "/"}`
        })
        bCrumb.push({
            text: data.category.breadcrumbAcf.breadcrumbName,
        })
        return bCrumb;
    }

    return (
        <Layout
            headerMenu={props.pageContext.headerMenu}
            footerMenu={props.pageContext.footerMenu}
            currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
            availableVersions={props.pageContext.availablePages}
            metaData={constructMetaData(data.category, props.pageContext.currentPage, props.pageContext.language)}
            services={props.pageContext.servicesCategories}
            projects={props.pageContext.projectsCategories}
            preHeader={data.template.contactInformationTemplate}
        >
            <div>
                {data.category.breadcrumbAcf.breadcrumbName && (
                    <section>
                        <Breadcrumb list={getBreadcrumb()} image={data.category.breadcrumbAcf.image} h1={data.category.breadcrumbAcf.breadcrumbName} />
                    </section>
                )}
                <section>
                    <div className="container w-full m-auto my-12 md:my-20">
                        <div className="w-full flex flex-wrap">
                            <div className="w-full flex flex-wrap">
                                {data.posts.nodes.map((post, i) => {
                                    return (
                                        <article className="w-full md:w-1/2 lg:w-1/3 p-4" key={`post-tab-${i}`}>
                                            <Link to={`/nea/${data.category.slug}/${post.slug}/`}>
                                                <div className="relative bp-article">
                                                    <div className="blog-f-img" style={{ padding: "8px" }}>
                                                        {post.featuredImage && (
                                                            <GatsbyImage
                                                                image={post.featuredImage.node.imageFile.childImageSharp.gatsbyImageData}
                                                                alt={post.featuredImage.node.altText}
                                                                className="h-full w-full shadow bp-img-wrapper"
                                                                loading="lazy" />
                                                        )}
                                                    </div>
                                                    <div className="w-full z-10">
                                                        <div className="flex px-2 md:px-6 blog-info pt-2">
                                                            <div className="flex justify-center">
                                                                <div>
                                                                    <img src={User} style={{ width: "15px", marginRight: "5px" }} />
                                                                </div>
                                                                <div>
                                                                    {post.author.node.firstName}
                                                                </div>
                                                            </div>
                                                            <div className="px-2">-</div>
                                                            <div className="flex justify-center">
                                                                <div>
                                                                    <img src={Calendar} style={{ width: "15px", marginRight: "5px" }} />
                                                                </div>
                                                                <div>{format(new Date(post.date), 'dd MMMM, yyyy', { locale: el })}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=" px-2 md:px-6 pt-2 bp-info">
                                                        <h2>{htmlDecode(post.title)}</h2>
                                                        <div className="bp-excerpt mt-4" dangerouslySetInnerHTML={{ __html: post.excerpt }}></div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </article>

                                    );
                                })}
                            </div>
                        </div>

                        <ul className="flex justify-center py-8">
                            {props.pageContext.currentPage === 2 && (
                                <li className="mr-4 relative"  >
                                    <Link
                                        to={`/nea/${data.category.slug}/`}
                                        className={"py-2 px-4 pagination-link bp-arrow"}
                                    >
                                    </Link>
                                </li>
                            )}

                            {props.pageContext.currentPage > 2 && (
                                <li className="mr-4 relative"  >
                                    <Link
                                        to={`/nea/${data.category.slug}/${props.pageContext.currentPage - 1}/`}
                                        className={"py-2 px-4 pagination-link bp-arrow"}
                                    >
                                    </Link>
                                </li>
                            )}

                            {props.pageContext.numberOfPages > 1 && (Array.from({ length: props.pageContext.numberOfPages }).map((page, index) => {
                                return (
                                    <li key={index} className={`mr-4 `}  >
                                        <Link
                                            to={index === 0 ? `/nea/${data.category.slug}/` : `/nea/${data.category.slug}/${index + 1}/`}
                                            className={props.pageContext.currentPage === index + 1 ? "active-pagination-link py-2 px-4 pagination-link" : "py-2 px-4 pagination-link"}
                                        >
                                            {index + 1}
                                        </Link>
                                    </li>
                                )
                            }))}

                            {props.pageContext.currentPage < props.pageContext.numberOfPages && (
                                <li className="mr-4 relative"  >
                                    <Link
                                        to={`/nea/${data.category.slug}/${props.pageContext.currentPage + 1}/`}
                                        className={"py-2 px-4 pagination-link bp-arrow-r"}
                                    >
                                    </Link>
                                </li>
                            )}

                        </ul>
                    </div>
                    <div className="w-full">
                        <h2 className="text-center">Κατηγορίες</h2>
                        <div className="sc-navigation">
                            {data.categories.nodes.map((cat, index) => (
                                <div key={`blog-cat-${index}`}>
                                    <ul>
                                        <li className="sv-li" style={false ? { color: "#ffc329" } : { color: "inherit" }}>
                                            <Link to={`/nea/${cat.slug}/`} style={{padding: "20px 10px"}}>
                                                {cat.name}
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </div>

        </Layout>
    );
}

export default BlogPageTemplate;

export const blogPageQuery = graphql`query GET_BLOG_PAGE($id: ID!, $databaseId: Int! ,$themeTemplateUri: ID!, $startPoint: String!, $postsPerPage: Int!) {
  wpgraphql {

    category(id: $id) {
        seo {
          canonical
          title
          metaDesc
          opengraphDescription
          opengraphTitle
          opengraphImage {
            sourceUrl
          }
        }
        breadcrumbAcf {
          breadcrumbName
          image {
            sourceUrl
            altText
            imageFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
        }
        slug
        name
    }

    categories(first: 100, where: {language: EL}) {
        nodes {
          id
          databaseId
          slug
          name
        }
    }

    template(id: $themeTemplateUri, idType: SLUG) {
      contactInformationTemplate {
        telephoneNumber
        mobileNumber
        supportEmail
        hoursLabel
        hoursTitle
        form {
          title
          fullName
          email {
            label
            errorMessage
          }
          subject
          message {
            label
            errorMessage
          }
          telephone {
            label
            errorMessage
          }
          sendButton
        }
        responseMessages {
          success
          failed
        }
      }
    }
    posts(after: $startPoint, first: $postsPerPage, where: {language: EL , categoryId: $databaseId}) {
      nodes {
        title
        excerpt
        slug
        date
        author {
          node {
            firstName
            lastName
            avatar {
              url
            }
          }
        }
        featuredImage {
          node {
            sourceUrl
            altText
            imageFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
}
`;
